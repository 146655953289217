import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const screenshots = [
  require("../../images/app_screenshot_1.jpg"),
  require("../../images/app_screenshot_2.jpg"),
  require("../../images/app_screenshot_3.jpg"),
  require("../../images/app_screenshot_4.jpg"),
  require("../../images/app_screenshot_5.jpg"),
  require("../../images/app_screenshot_6.jpg"),
  require("../../images/app_screenshot_7.jpg"),
  require("../../images/app_screenshot_8.jpg"),
  require("../../images/app_screenshot_9.jpg"),
  require("../../images/app_screenshot_10.jpg"),
  require("../../images/app_screenshot_11.jpg"),
  require("../../images/app_screenshot_12.jpg")
];

const ScreenshotSlider = () => {
  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    beforeChange: (current, next) => {
      setActiveIndex(next);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "0"
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0"
        }
      }
    ]
  };
  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <section className="overflow-x-clip py-16">
      <div className="md:w-[80%] w-full mx-auto">
        <div className="lg:w-1/2 md:w-2/3 sm:w-full p-5 mx-auto flex flex-col gap-3 mb-16">
          <h2 className="text-center md:text-5xl  text-3xl font-bold">
            App ScreenShots
          </h2>
          <div>
            <div className="">
              <p className="text-center">
                Beat your personal best – Be the best in the city – Rise up the
                leaderboard and beat challenges one by one. Find your Jogging
                mate to keep you motivated for your next challenge.
              </p>
            </div>
          </div>
        </div>
        <Slider {...settings} className="mb-8" ref={sliderRef}>
          {screenshots.map((screenshot, index) => (
            <div key={index} className="px-1 relative">
              {index === activeIndex ? (
                <img
                  src={screenshot}
                  alt={`Screenshot ${index}`}
                  className="mx-auto w-100 h-100 rounded-[2rem]"
                />
              ) : (
                <div className="">
                  <img
                    src={screenshot}
                    alt={`Screenshot ${index}`}
                    className="mx-auto my-auto w-[100%] h-100 "
                  />
                </div>
              )}
              {index === activeIndex && (
                <div className="phone-frame absolute inset-0 flex justify-center items-center">
                  <img
                    src="https://themexriver.com/appilo-theme/main/wp-content/uploads/sites/2/2019/12/iphone-moc-1-13-1-1-3-1.png"
                    alt="iPhone Mockup"
                    className="mx-auto w-100 h-[100%] "
                  />
                </div>
              )}
            </div>
          ))}
        </Slider>
        <div className="navigation-arrows flex justify-center mt-3 gap-4">
          <div
            className="bg-gray-200 flex items-center justify-center p-4 rounded-full cursor-pointer"
            onClick={handlePrev}
          >
            <FaArrowLeft className="arrow text-xl text-gray-800" />
          </div>
          <div
            className="bg-gray-200 flex items-center justify-center p-4 rounded-full cursor-pointer"
            onClick={handleNext}
          >
            <FaArrowRight className="arrow text-xl text-gray-800" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScreenshotSlider;
