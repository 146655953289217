import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  FiShield,
  FiSmartphone,
  FiBarChart2,
  FiHeadphones,
  FiCloud,
  FiSettings,
} from "react-icons/fi";




const features = [
  {
    icon: <FiShield size={40} className="text-center" />,
    title: "YOUR FITNESS PARTNER",
    description:
      "A gamified experience to keep you motivated – To stay fit and healthy. Keep you on track to win your personal best and achieve more.",
  },
  {
    icon: <FiSmartphone size={40} className="text-center" />,
    title: "BE THE LEADER OF THE CHARTS",
    description:
      "City, Country and Global Leaderboards to display leaders of daily, weekly, monthly and all time champions. Be the leader in your category.",
  },
  {
    icon: <FiBarChart2 size={40} className="text-center" />,
    title: "CONSISTENCY IS THE KEY!",
    description:
      "Be consistent in your daily routine, get on a streak with your friends to take steps daily, and keep you in shape. Get your streak growing.",
  },
  {
    icon: <FiHeadphones size={40} />,
    title: "KEEP IT SOCIAL, ENGAGING AND INTERACTIVE",
    description:
      "We believe in the social value of our users, and we value the importance of keeping our community connected. Connect with your Oengoo community.",
  },
  {
    icon: <FiCloud size={40} />,
    title: "SIMPLIFIED UI AND UX",
    description:
      "We tried our best to give you the most simplified user interface and user experience that you will love, and will be easy for you to use.",
  },
  // {
  //   icon: <FiSettings size={40} />,
  //   title: "Achievements",
  //   description:
  //     "Tailor the app to your preferences with a wide range of customization options.",
  // },
];

const FeatureSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    beforeChange: (current, next) => {
      setActiveIndex(next);
    },
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1500, 
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
    ],
  };


  return (
    <div className="w-full md:w-[80%] mx-auto">
      <Slider {...sliderSettings} className="mt-6">
        {features.map((feature, index) => {
          return (
            <div
              key={index}
              className={`single-app-feature min-h-[17.3rem]  ${
                index === activeIndex ? "active" : ""
              }`}
              
            >
              <div className="">
                <div className="icon-container">{feature.icon}</div>
                <h3 className="my-4 text-xl font-bold">{feature.title}</h3>
                <p className="mb-4 text-gray-700 line-clamp-4">
                  {feature.description}
                </p>
              </div>
              {index === activeIndex && <div className="line-bg"></div>}
              {index !== activeIndex && <div className="line-bg"></div>}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
export default FeatureSlider;
