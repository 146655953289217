import React, { useState } from "react";
import { FiCircle, FiChevronRight } from "react-icons/fi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import blogsData from "../../data/blogs_data";
import { Link } from "react-router-dom";

function LatestNews() {
  return (
    <section className="py-16">
      <div className="md:w-[80%] w-full mx-auto">
        {/* heading */}
        <div className="flex flex-col gap-3 p-5 mx-auto mb-16 lg:w-1/2 md:w-2/3 sm:w-full">
          <h2 className="text-3xl font-bold text-center md:text-5xl">
            Our Latest News
          </h2>
          <div>
            <div className="">
              <p className="text-center">
                Oengoo gives you the social connectivity with the fitness
                enthusiasts around you, leaderboard to compete with your fitness
                community for daily activities.
                </p>
            </div>
          </div>
        </div>
        {/* slider */}
        <BlogSlider />
      </div>
    </section>
  );
}

export default LatestNews;



const BlogSlider = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => {
      setActiveIndex(next);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0"
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0"
        }
      }
    ]
  };
  return (
    <section className="overflow-x-hidden blog-style-one">
      <div className="container mx-auto">
        <Slider {...settings}>
          {blogsData.map((post, index) => (
            <div
              key={index}
              className={`item p-4 rounded-lg  bg-white ${
                index === activeIndex ? "active" : ""
              } `}
            >
              <Link to={"/blogs/"+post.id}>
              <div>
                <div className="img-box">
                  <img
                    src={post.image}
                    alt="Blog Post"
                    className="w-full rounded-md hover:opacity-60"
                  />
                </div>
                <div className="mt-4 mb-6 text-box">
                  <div className="flex items-center gap-2 mb-2 text-xs">
                    <p>Oengoo</p>
                    <FiCircle
                      size={6}
                      className="text-[#E64900]  fill-current mr-1"
                    />
                    <p>{post.date}</p>
                    <FiCircle
                      size={6}
                      className="text-[#E64900] fill-current mr-1"
                    />
                    <p>Admin</p>
                  </div>
                  <a href={post.link}>
                    <h3 className="text-lg font-semibold leading-6 mb-2 hover:text-[#E64900]">
                      {post.title}
                    </h3>
                  </a>
                  <p className="text-xs text-gray-700 line-clamp-2">
                    {post.excerpt}
                  </p>
                  <a
                    href={post.link}
                    className="read-more flex items-center gap-2 pt-4 text-sm text-[#E64900]"
                  >
                    Read More <FiChevronRight className="text-[#E64900]" />
                  </a>
                </div>
              </div>
              </Link>
              {index === activeIndex && <div className="line-bg"></div>}
              {/* {index !== activeIndex && <div className="line-bg"></div>} */}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
