import React from "react";
import BannerComponent from "./banner";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import FunFactSection from "./funfact";
import AppFeaturesSection from "./app_features";
import "../../styles/home.css";
import HowAppWorksSection from "./app_work";
import Discover from "./discover";
import Clients from "./app_clients";
import LatestNews from "./latest_new";

function Home() {
  return (
    <div>
      <Navbar />
      <BannerComponent />
      <FunFactSection />
      <AppFeaturesSection />
      <HowAppWorksSection />
      <Discover />
      <Clients />
      <LatestNews />
      <Footer />
    </div>
  );
}

export default Home;
