import React from "react";
import CountUp from "react-countup";

const FunFactItem = ({ number, label }) => {
  return (
    <div className="">
      <div className="">
        <p className="text-center">
          <span className="counter me-1 text-center text-gray-600 m-0 p-0 border-0 outline-0 box-border bg-gradient-to-br from-orange-500 to-red-500 bg-clip-text text-transparent font-light text-5xl">
            <CountUp end={number} duration={2} />
          </span>
          <span className="text-gray-600 text-lg font-semibold">{label}</span>
        </p>
      </div>
    </div>
  );
};

const FunFactSection = () => {
  return (
    <section className="md:w-[80%] w-full mx-auto">
      <div className="md:w-[90%] w-full mx-auto">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 lg:gap-10 gap-5">
          <FunFactItem number={1265} label="Users" />
          <FunFactItem number={1000} label="Download" />
          <FunFactItem number={508} label="Likes" />
          <FunFactItem number={809} label="5 star Rating" />
        </div>
      </div>
      <div className="border mt-10"></div>
    </section>
  );
};

export default FunFactSection;
