import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { FaCheckCircle } from "react-icons/fa";
import Banner from "../../components/banner";

function AccountDeletionRequest() {
  const [email, setEmail] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

   useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Assume a successful submission, set success message accordingly
    setSuccessMessage("Our team will contact you within one business day.");

    // Display success modal
    setShowSuccessModal(true);

    // Clear email and hide modal after 20 seconds
    setTimeout(() => {
      setEmail("");
      setShowSuccessModal(false);
    }, 2000);
  };

  const handleCloseModal = () => {
    setEmail("");
    setShowSuccessModal(false);
  };

  return (
    <div>
      <Navbar />
      <Banner title={"Account Deletion Request"} />
      <div className="md:w-[80%] w-full mx-auto md:p-8 p-4" id="top">
        <div className="p-2 m-2 bg-orange-50 md:m-10 md:p-10 md:rounded-3xl rounded-xl">
          <div className="mx-auto p-8">
            <h1 className="text-xl md:text-3xl font-bold mb-8 text-center">
              Account Deletion Request
            </h1>
            <form onSubmit={handleSubmit} className="max-w-md mx-auto">
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  className="w-full border rounded-md py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter your email address"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Submit Request
              </button>
            </form>

            {/* Success Modal */}
            {showSuccessModal && (
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="flex flex-col items-center p-8 bg-white rounded-lg shadow-lg">
                  <FaCheckCircle className={`text-green-600 text-4xl mb-4`} />
                  <p className="text-lg text-black">{successMessage}</p>
                  <button
                    onClick={handleCloseModal}
                    className={`mt-4 px-4 py-2 bg-orange-500 text-white rounded-md  focus:outline-none`}
                  >
                    Okay
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AccountDeletionRequest;
