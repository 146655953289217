import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Banner from "../../components/banner";
import TeamCard from "./team_card";

function Teams() {
  const teamData = [
    {
      name: "Muhammad Hilal",
      image: require("../../images/Hilal.jpg"),
      designation: "CRO",
      linkedIn: "https://www.linkedin.com/in/hilalkhaan/"
    },
    {
      name: "Tahir Saddam",
      image: require("../../images/team_2.jpg"),
      designation: "CTO",
      linkedIn: "https://www.linkedin.com/in/tahirsaddam/"
    },
    {
      name: "Faisal Khan",
      image: require("../../images/team_3.jpeg"),
      designation: "Sr App Developer",
      linkedIn: "https://www.linkedin.com/in/faisal-khalid3535/"
    },
    {
      name: "Farooq Saeed",
      image: require("../../images/team_4.png"),
      designation: "Sr Backend Developer",
      linkedIn: "https://www.linkedin.com/in/farooq-saeed-full-stack-dev/"
    },
    {
      name: "Tahseen Ullah",
      image: require("../../images/tehseen.jpg"),
      designation: "Full Stack Developer",
      linkedIn: "https://www.linkedin.com/in/tahseen-ullah-bb155915b/"
    },
    {
      name: "Hasnat Amin",
      image: require("../../images/hasnat.png"),
      designation: "Frontend Developer",
      linkedIn: "https://www.linkedin.com/in/hasnat-amin-05/"
    },
    {
      name: "Abbas Khan",
      image: require("../../images/Abbas.png"),
      designation: "Marketing Manager",
      linkedIn: "https://www.linkedin.com/in/abbas-khan-641177135/"
    }
  ]
  return (
    <div>
      <Navbar />

      <Banner title={"Team"} />

      <div className="md:w-[80%] w-full mx-auto my-20">
        <div className="flex flex-row flex-wrap justify-start items-center gap-[9px]">
          {
            teamData.map((item,index)=>{
              return <TeamCard name={item.name} image={item.image} designation={item.designation} linkedIn={item.linkedIn} />
            })
          }
          
         
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Teams;
