const blogsData = [
  {
    id: 1,
    title: "Oengoo: Your Ultimate Fitness Partner",
    image: require("../images/blogs_1.jpg"),
    date: "Oct 10, 2023",
    content: [
      {
        subheading: "Compete and Win",
        paragraph:
          "Oengoo provides the perfect blend of social connectivity and competition, offering you the opportunity to engage with fitness enthusiasts around the world. One of its standout features is the leaderboard, which allows you to compete with your fitness community in daily activities. This competitive element adds an extra layer of motivation to your fitness journey."
      },
      {
        subheading: "Building Your Fitness Streak",
        paragraph:
          "We all know that consistency is key when it comes to fitness. Oengoo understands this and encourages you to build a fitness streak with your friends. This streak is a daily step goal that keeps you motivated, ensuring you stay on track to achieve your fitness objectives. With Oengoo, the message is clear: Keep moving, keep winning."
      },
      {
        subheading: "Beat Your Personal Best",
        paragraph:
          "Oengoo challenges you to beat your personal best and become the best in your city. It's not just about achieving your own goals; it's about rising up the leaderboard and conquering challenges one by one. To make the journey even more enjoyable, Oengoo helps you find jogging partners who can keep you motivated and inspired for your next challenge. The app is your ultimate fitness partner in this exciting adventure."
      },
      {
        subheading: "Gamified Fitness Experience",
        paragraph:
          "What sets Oengoo apart is its gamified approach to fitness. It provides an immersive experience that keeps you motivated and on track to achieve your fitness goals. The gamification elements add an element of fun to your workouts, making staying fit and healthy an enjoyable experience."
      },
      {
        subheading: "Be the Leader of the Charts",
        paragraph:
          "Oengoo doesn't stop at individual achievements; it also celebrates community success. The app features city, country, and global leaderboards that display the leaders of daily, weekly, monthly, and all-time champions. This friendly competition motivates users to strive for excellence and be the leader in their respective categories."
      },
      {
        subheading: "Consistency Is the Key",
        paragraph:
          "Staying consistent with your daily fitness routine is crucial, and Oengoo recognizes this. It encourages you to maintain a streak by taking daily steps with your friends, helping you stay in shape and grow your streak. This focus on consistency is one of the key factors that make Oengoo an effective fitness companion."
      }
    ]
  },
  {

    id: 2,
    title: "Join Oengoo - Your Fitness Partner",
    image: require("../images/blogs_2.jpg"),
    date: "Oct 10, 2023",
    content: [
      {
        subheading: "Join Oengoo - Your Fitness Partner",
        paragraph:
          "Embark on an exciting journey towards a healthier and more active lifestyle! Oengoo is your ultimate fitness companion, offering a gamified experience that keeps you motivated, connected, and on track to achieve your fitness goals.",
      },
      {
        subheading: "Seamless Fitness Tracking",
        paragraph:
          "With Oengoo, you'll enjoy a seamless and user-friendly interface that makes tracking your fitness activities a breeze. Whether you're jogging, walking, or engaging in other physical activities, Oengoo helps you stay accountable and motivated every step of the way.",
      },
      {
        subheading: "Connect with a Vibrant Community",
        paragraph:
          "Connect with a vibrant community of fitness enthusiasts who share your passion for staying fit and healthy. Engage in friendly competition, challenge your friends, and climb the leaderboards to showcase your progress. Oengoo provides city, country, and global leaderboards for daily, weekly, monthly, and all-time champions, so you can strive to be the best in your category.",
      },
      {
        subheading: "Emphasis on Community Connectivity",
        paragraph:
          "We understand the importance of social interaction in the fitness journey, and that's why Oengoo emphasizes community connectivity. Share your achievements, exchange tips, and provide support to fellow users within the app. Celebrate your fitness milestones together and draw inspiration from others who are on their own fitness journey.",
      },
    ],
  },
  {
    id: 3,
    title: "Oengoo - Tackling the Crisis of Physical Inactivity",
    image: require("../images/blogs_3.jpg"),
    date: "Oct 10, 2023",
    content: [
      {
        subheading: "The Crisis of Physical Inactivity",
        paragraph:
          "The world is facing a crisis of physical inactivity. As reported by the World Health Organization, a decline in physical activity levels is preventing many individuals from achieving optimal health and wellness. This lack of physical activity has been identified as the fourth leading risk factor for global mortality, contributing to six percent of deaths worldwide. In addition, it also increases the risk of non-communicable diseases.",
      },
      {
        subheading: "Oengoo's Comprehensive Solution",
        paragraph:
          "Oengoo offers a comprehensive solution to the challenges of physical inactivity. Our platform includes:",
      },
      {
        subheading: "Social Interaction",
        paragraph:
          "A social interaction platform for fitness enthusiasts to connect, share their progress, and provide accountability and motivation for each other.",
      },
      {
        subheading: "Fitness Community",
        paragraph:
          "Gamified experience of healthy routine, jogging and walking to the fitness communities.",
      },
      {
        subheading: "Gamification",
        paragraph:
          "Leaderboard, rewards, streaks, and challenges to motivate users to establish and stick to a regular exercise routine.",
      },
    ],
  },
  // Add more blog entries as needed
];

export default blogsData;
